import { Tooltip } from 'react-tooltip';

export const Link = ({ children }) => {
  return <>
    <div
      data-tooltip-id={`tooltip-${children?.slice(0,10)}`}
      data-tooltip-content={children || ''}
      data-tooltip-place="top"
      data-tooltip-offset={5}
    >
      <svg xmlns="http://www.w3.org/2000/svg" className="" viewBox="0 0 512 512"><path d="M200.66 352H144a96 96 0 010-192h55.41M312.59 160H368a96 96 0 010 192h-56.66M169.07 256h175.86" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48"/></svg>
    </div>
    <Tooltip id={`tooltip-${children?.slice(0,10)}`} style={{ borderRadius: 10, maxWidth: 500 }} clickable />
  </>;
};