import { useContext, useEffect, useState } from "react";
import { Card } from "../../Card";
import { client } from "../../../api";
import { IdCopyTableItem } from "../Utility/IdCopyTableItem";
import { SimpleInputModalTableItem } from "../Utility/SimpleInputModalTableItem";
import { ModalContext } from "../../../contexts/modal.context";
import dayjs from "dayjs";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const States = ({ orderId, agentName, leadCountRequested, states, disabled, update }) => {
  const [edited, setEdited] = useState(false);
  const [editedStates, setStates] = useState(states || []);
  useEffect(() => {
    setStates(states);
  }, [states]);

  const { openModal } = useContext(ModalContext);

  const handleEditClick = () => {
    openModal({
      type: 'state',
      prompt: `Edit States for ${agentName} (${orderId.slice(19)})`,
      states,
      onSave: (states) => {
        const editOrder = async () => {
          await client({
            method: 'POST',
            url: '/editOrderStates',
            headers: {
              "Content-Type": "application/json",
            },
            data: {
              states,
              orderId,
              leadCountRequested,
            },
          });
          setStates(states);
          setEdited(true);
          setTimeout(() => {
            setEdited(false);
          }, 2000);

          update();
        };

        editOrder();
      }
    });
  };

  return (
    <div
      className={`table-cell text-right text-xs rounded-md font-medium pl-2 ${disabled ? 'text-gray-500 cursor-default' : !edited ? 'text-blue-500 underline cursor-pointer' : 'text-white bg-green-500'} overflow-x-auto w-32 whitespace-nowrap display-none`}
      onClick={disabled ? () => { } : handleEditClick}
      style={{
        // For Chrome, Safari and Opera
        WebkitOverflowScrolling: 'touch',
        '::-webkit-scrollbar': {
          display: 'none'
        },
        // For Firefox
        scrollbarWidth: 'none',
        // For IE and Edge
        msOverflowStyle: 'none',
      }}
    >
      <div className="flex flex-row">
        <div className="overflow-x-auto w-32 display-none">
          {!edited ? `${agentName === 'Total' ? '' : editedStates?.join(', ')}` : 'edited!'}
        </div>
      </div>
    </div>
  );
};

export const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [showArchives, setShowArchives] = useState(false);
  const [totalLeadCountCaptured, setTotalLeadCountCaptured] = useState(0);
  const [totalLeadCountRequested, setTotalLeadCountRequested] = useState(0);
  const [totalLeadCountRemaining, setTotalLeadCountRemaining] = useState(0);

  const updateOrders = async () => {
    const { data } = await client({
      method: 'GET',
      url: '/orders',
      headers: {
        "Content-Type": "application/json",
      },
    });

    setOrders(data);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const { data } = await client({
        method: 'GET',
        url: '/orders',
        headers: {
          "Content-Type": "application/json",
        },
      });

      setOrders(data || []);
    };

    fetchOrders();

  }, []);

  useEffect(() => {

    // Create a Set of orders with unique orderIds
    const { result: uniqueOrders } = orders.reduce((acc, order) => {
      if (!acc.map[order.orderId] && (showArchives || order.leadsRemaining)) {
        acc.map[order.orderId] = true;  // Remember that we've seen this orderId.
        acc.result.push(order);          // Add the current order to the result array.
      }
      return acc;
    }, { map: {}, result: [] })

    const totals = uniqueOrders
      .reduce((p, { leadCountRequested, leadCountRemaining }) => {
        const totalLeadCountRequested = (leadCountRequested || 0) + (p.totalLeadCountRequested || 0);
        const totalLeadCountRemaining = (leadCountRemaining || 0) + (p.totalLeadCountRemaining || 0);
        return {
          ...p,
          totalLeadCountRequested,
          totalLeadCountRemaining,
        }
      }, {});

    setTotalLeadCountCaptured((totals?.totalLeadCountRequested || 0) - (totals?.totalLeadCountRemaining || 0));
    setTotalLeadCountRequested(totals?.totalLeadCountRequested || 0);
    setTotalLeadCountRemaining(totals?.totalLeadCountRemaining || 0);
  }, [orders, showArchives])

  const showArchivesHandler = () => {
    setShowArchives(!showArchives);
  }

  return <div className='flex flex-col space-y-5 w-full'>
    <Card
      title={'Orders'}
      prompt={false}
      showArchivesCheckbox={true}
      showArchivesFn={showArchivesHandler}
    >
      <div className='table w-full overflow-y-auto'>
        <div className='table-row space-y-2'>
          <div className='table-cell text-left text-xs font-bold pl-2 text-slate-gray border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Id
          </div>
          <div className='table-cell text-center text-xs font-bold pl-2 text-slate-gray border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Create Date
          </div>
          <div className='table-cell text-center text-xs font-bold pl-2 text-slate-gray border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Fulfillment Date
          </div>
          <div className='table-cell text-left text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Agent Name
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Captured
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Ordered
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Remaining
          </div>
          <div className='table-cell text-center text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            States
          </div>
          <div className='table-cell text-center text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            #
          </div>
        </div>
        {
          orders?.filter(({ leadsRemaining, leadCountRemaining, archived }) => showArchives || ((archived !== undefined ? !archived : leadsRemaining) && leadCountRemaining > 0))
            .reduce((acc, { orderId, agentId, leadCountRequested, leadCountRemaining, state, agentName, archived, leadsRemaining, createdAt, fulfilledAt }) => {
              const existing = acc.find(({ orderId: _orderId }) => _orderId === orderId);

              if (existing) {
                // existing.leadCountCaptured += leadCountCaptured;
                existing.states = existing?.states || [];
                existing.states.push(state);
                existing.archived = archived || existing.archived;
                existing.leadCountRequested = +leadCountRequested || existing.leadCountRequested;
                existing.leadCountCaptured = (+leadCountRequested - (+leadCountRemaining)) || existing.leadCountCaptured;
                existing.leadCountRemaining = +leadCountRemaining || existing.leadCountRemaining;
                existing.leadsRemaining = leadsRemaining || existing.leadsRemaining;
                existing.states.sort();
              } else  {
                acc.push({
                  orderId,
                  agentId,
                  state,
                  states: [state],
                  agentName,
                  archived,
                  leadCountRequested: +leadCountRequested,
                  leadCountCaptured: +leadCountRequested - (+leadCountRemaining),
                  leadCountRemaining: +leadCountRemaining,
                  leadsRemaining,
                  createdAt,
                  fulfilledAt,
                });
              }

              return acc;
            }, [])
            .sort((a, b) => {
              if (a.orderId < b.orderId) {
                return -1;
              }
              if (a.orderId > b.orderId) {
                return 1;
              }
              return 0;
            })
            .map((data) => {
              const { orderId, leadCountRequested, leadCountCaptured, leadCountRemaining, agentName, states, createdAt, fulfilledAt, leadsRemaining } = data;

              return <div className={`table-row pb-1`}>
                <div className={`table-cell rounded ${leadsRemaining === false && leadCountRemaining > 0 ? 'bg-amber-500' : ''}`}>
                  <IdCopyTableItem id={orderId} />
                </div>
                <div className='table-cell text-center text-xxs pl-2 text-slate-gray'>
                  {createdAt ? `${dayjs(createdAt * 1000).tz('America/New_York').format('MM/DD/YYYY hh:mm a')}` : 'n/a'}
                </div>
                <div className='table-cell text-center text-xxs pl-2 text-slate-gray'>
                  {fulfilledAt ? dayjs(fulfilledAt * 1000).tz('America/New_York').format('MM/DD/YYYY hh:mm a') : createdAt ? 'TBD' : 'n/a'}
                </div>
                <div className='table-cell text-left text-xs pl-2 text-slate-gray'>
                  {agentName}
                </div>
                <div className='table-cell text-right text-xs pl-2 text-slate-gray'>
                  {leadCountCaptured}
                </div>
                <SimpleInputModalTableItem
                  labelText={`Edit Amount Ordered for ${agentName} (${orderId.slice(19)})`}
                  endpoint='/editOrderCountRequested'
                  value={leadCountRequested}
                  fieldName='leadCountRequested'
                  identifyingData={{ orderId, states }}
                  updateOrders={updateOrders}
                  disabled={showArchives}
                />
                <div className='table-cell text-right text-xs pl-2 text-slate-gray'>
                  {leadCountRemaining}
                </div>
                <States {...data} disabled={showArchives} update={updateOrders} />
                <div className='table-cell text-center text-xs pl-2 text-slate-gray'>
                  {states.length}
                </div>
              </div>
            })
        }
        <div className='table-row pb-1'>
          <div className='table-cell text-left text-xs pl-2 text-slate-gray font-bold'>
          </div>
          <div className='table-cell text-left text-xs pl-2 text-slate-gray font-bold'>
          </div>
          <div className='table-cell text-left text-xs pl-2 text-slate-gray font-bold'>
          </div>
          <div className='table-cell text-right text-xs pl-2 text-slate-gray font-bold'>
            Total
          </div>
          <div className='table-cell text-right text-xs pl-2 text-slate-gray font-bold'>
            {totalLeadCountCaptured}
          </div>
          <div className='table-cell text-right text-xs pl-2 text-slate-gray font-bold'>
            {totalLeadCountRequested}
          </div>
          <div className='table-cell text-right text-xs pl-2 text-slate-gray font-bold'>
            {totalLeadCountRemaining}
          </div>
          <div className='table-cell text-left text-xs pl-2 text-slate-gray font-bold'>
          </div>
          <div className='table-cell text-center text-xs pl-2 text-slate-gray font-bold'>
          </div>
        </div>
      </div>
    </Card>
  </div>;

};