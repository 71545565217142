import { useState } from "react";

export const IdCopyTableItem = ({ id }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(id)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);  // Reset the copied state after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy ID:', err);
      });
  };

  return (
    <div
      className={`table-cell text-xs text-center rounded-md font-medium ${!copied ? 'text-blue-500 underline' : 'text-white bg-green-500'} cursor-pointer `}
      onClick={handleCopyClick}
    >
      {!copied ? id.slice(19) : 'copied!'}
    </div>
  );
};