import { useContext, useRef, useState } from "react";
import { ModalContext } from "../../contexts/modal.context";
import { Loading } from "../Loading";

const stateListings = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MD: 'Maryland',
  ME: 'Maine',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

const FileInput = ({ modal: { onFileSelect }, loading, closeModal }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && onFileSelect) {
      console.log('file', file);
      onFileSelect(file);
    }
  };

  return !loading ? <>
    <div>
      <h2 className="text-2xl mb-3 text-black text-center">
        Select a file to upload
      </h2>
    </div>
    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileChange}
    />
    <div className="flex justify-center">
      <button className="bg-blue-600 text-white hover:bg-blue-400 font-bold mx-2 py-2 px-4 mt-3 rounded" onClick={closeModal}>Cancel</button>
    </div>
  </> : <>
    <Loading />
  </>;
}


const SimpleConfirm = ({ modal: { prompt, onSave }, closeModal }) => {
  const onClick = () => {
    onSave();
    closeModal();
  };

  return <>
    <div>
      <h2 className="text-2xl mb-3 text-black text-center">{prompt || ''}</h2>
    </div>
    <div className="flex">
      <button className="bg-blue-600 text-white hover:bg-blue-400 font-bold mx-2 py-2 px-4 mt-3 rounded" onClick={onClick}>Confirm</button>
      <button className="bg-blue-600 text-white hover:bg-blue-400 font-bold mx-2 py-2 px-4 mt-3 rounded" onClick={closeModal}>Cancel</button>
    </div>
  </>;
};

const SimpleInput = ({ modal: { prompt, onSave }, closeModal }) => {
  const [value, setValue] = useState('');

  const onClick = () => {
    onSave(value);
    closeModal();
  };

  return <>
    <div>
      <h2 className="text-2xl mb-3 text-black text-center">{prompt || ''}</h2>
    </div>
    <div>
      <input
        className="border-2 border-solid border-black text-center w-full"
        onChange={(e) => {
          setValue(e.target.value);
        }}
      ></input>
    </div>
    <div className="flex">
      <button className="bg-blue-600 text-white hover:bg-blue-400 font-bold mx-2 py-2 px-4 mt-3 rounded" onClick={onClick}>Save Change</button>
      <button className="bg-blue-600 text-white hover:bg-blue-400 font-bold mx-2 py-2 px-4 mt-3 rounded" onClick={closeModal}>Cancel</button>
    </div>
  </>;
};

const StateInput = ({ modal: { prompt, onSave, states }, closeModal }) => {
  const [editedStates, setEditedStates] = useState(states);

  const onClick = () => {
    onSave(editedStates);
    closeModal();
  };



  return <>
    <div>
      <h2 className="text-2xl mb-3 text-black text-center">{prompt}</h2>
    </div>
    <div>
      <ul className="checkboxModalList" onChange={
        (e) => {
          if (e.target.checked) {
            setEditedStates([...editedStates, e.target.value]);
          }
          else if (editedStates.length > 1) {
            setEditedStates(editedStates.filter((state) => state !== e.target.value));
          }
        }
      }>
        {Object.entries(stateListings).map(([key, value]) => {
          return <li className="checkboxModal">
            <input type="checkbox" value={key} checked={editedStates.includes(key)}></input>
            <label className="pl-1">{value}</label>
          </li>
        })}
      </ul>
    </div>
    <div>
      <button className="bg-blue-600 text-white hover:bg-blue-400 font-bold mx-2 py-2 px-4 mt-3 rounded" onClick={onClick}>Save Change</button>
      <button className="bg-blue-600 text-white hover:bg-blue-400 font-bold mx-2 py-2 px-4 mt-3 rounded" onClick={closeModal}>Cancel</button>
    </div>
  </>;
};

export const Modal = () => {
  const context = useContext(ModalContext);

  const { modal } = context;

  if (!modal) return null;

  return <div className="absolute flex flex-col top-0 left-0 w-full h-screen justify-center items-center">
    <div className="flex flex-col py-3 px-3 bg-white rounded-xl space-y-3 drop-shadow justify-center items-center">
      {modal?.type === 'state' ? <StateInput {...context} />
        : modal?.type === 'confirm' ? <SimpleConfirm {...context} />
          : modal?.type === 'file' ? <FileInput {...context} />
            : <SimpleInput {...context} />}
    </div>
  </div >;
};