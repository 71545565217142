import { useEffect, useState } from "react";
import { Card } from "../../Card";
import { client } from "../../../api";
import { IdCopyTableItem } from "../Utility/IdCopyTableItem";
import { SimpleInputModalTableItem } from "../Utility/SimpleInputModalTableItem";
import { DeleteButtonTableItem } from "../Utility/DeleteButtonTableItem";
import { UploadBanner } from "../Utility/UploadBanner";

export const AgentList = () => {
  const [agents, setAgents] = useState([]);

  const updateAgents = async () => {
    const { data } = await client({
      method: 'GET',
      url: '/agents',
      headers: {
        "Content-Type": "application/json",
      },
    });

    setAgents(data);
  }

  useEffect(() => {
    const fetchAgents = async () => {
      const { data } = await client({
        method: 'GET',
        url: '/agents',
        headers: {
          "Content-Type": "application/json",
        },
      });

      setAgents(data || []);
    };

    fetchAgents();

  }, []);

  return <div className='flex flex-col space-y-5 w-full'>
    <Card
      title={'Agents'}
      prompt={false}
    >
      <div className='table w-full overflow-y-auto'>
        <div className='table-row space-y-2'>
          <div className='table-cell text-left text-xs font-bold pl-2 text-slate-gray border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Id
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            First Name
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Last Name
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Phone Number
          </div>
          <div className='table-cell text-center text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Calendar URL
          </div>
          <div className='table-cell text-right text-xs font-bold text-slate-gray border-l-1 pl-2 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
            Google Sheet Id
          </div>
          <div className='table-cell text-center text-xs font-bold text-slate-gray border-l-1 pl-4 w-10 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
          </div>
          <div className='table-cell text-center text-xs font-bold text-slate-gray border-l-1 pl-4 w-10 border border-x-0 border-t-0 border-b-1 pb-2 mb-2'>
          </div>
        </div>
        {agents?.sort((a, b) => {
          const firstNameComparison = a.firstName?.localeCompare(b.firstName)

          if (firstNameComparison === 0) {
            return a.lastName?.localeCompare(b.lastName);
          }
          return firstNameComparison;
        })
          .map(({ agentId, firstName, lastName, phoneNumber, googleSheetId, banner, calendarUrl }) => <div className='table-row'>
            <IdCopyTableItem id={agentId} />
            <SimpleInputModalTableItem
              labelText={`Edit First Name for ${agentId.slice(19)}`}
              endpoint='/editAgentField'
              value={firstName}
              fieldName='firstName'
              identifyingData={{ agentId }}
              updateOrders={updateAgents}
            />
            <SimpleInputModalTableItem
              labelText={`Edit Last Name for ${agentId.slice(19)}`}
              endpoint='/editAgentField'
              value={lastName}
              fieldName='lastName'
              identifyingData={{ agentId }}
              updateOrders={updateAgents}
            />
            <SimpleInputModalTableItem
              labelText={`Edit Phone Number for ${agentId.slice(19)}`}
              endpoint='/editAgentField'
              value={phoneNumber}
              fieldName='phoneNumber'
              identifyingData={{ agentId }}
              updateOrders={updateAgents}
            />
            <SimpleInputModalTableItem
              labelText={`Edit Calendar URL for ${agentId.slice(19)}`}
              endpoint='/editAgentField'
              value={calendarUrl}
              fieldName='calendarUrl'
              identifyingData={{ agentId }}
              updateOrders={updateAgents}
            />
            <SimpleInputModalTableItem
              labelText={`Edit Google Sheet Id for ${agentId.slice(19)}`}
              endpoint='/editAgentField'
              value={googleSheetId}
              fieldName='googleSheetId'
              identifyingData={{ agentId }}
              updateOrders={updateAgents}
            />
            <UploadBanner {...{ agentId, banner }} />
            <DeleteButtonTableItem {...{ agentId, update: updateAgents }} />
          </div>)}
      </div>
    </Card>
  </div>;

};