import { useContext } from "react";
import { client } from "../../../../api";
import { ModalContext } from "../../../../contexts/modal.context";

export const DeleteButtonTableItem = ({ agentId, update }) => {
  const archiveAgent = async () => {
    await client({
      method: 'POST',
      url: '/editAgentField',
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        agentId,
        archived: true,
      },
    });
  };

  const { openModal } = useContext(ModalContext);

  const handleEditClick = () => {
    openModal({
      prompt: `Are you sure you want to delete agent ${agentId.slice(19)}?`,
      type: 'confirm',
      onSave: () => {
        (async () => {
          await archiveAgent();
          update(agentId);
        })();
      }
    });
  };

  return (
    <div className="table-cell justify-center items-center text-center relative">
      <div>
        <button
          className="font-black m-0 p-1 rounded w-6 relative"
          onClick={handleEditClick}>
          <svg className="relative top-[2px]" xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
            <path className="stroke-red-500" d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
            <path className="stroke-red-500" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 112h352" />
            <path className="stroke-red-500" d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          </svg>
        </button>
      </div>
    </div>
  );
};