import { useContext, useState } from "react";
import { client } from "../../../../api";
import { ModalContext } from "../../../../contexts/modal.context";
import axios from "axios";

export const UploadBanner = ({ agentId, banner }) => {
  const { openModal, closeModal, setLoading } = useContext(ModalContext);
  const [bannerState, setBanner] = useState(banner);

  const upload = async (file) => {
    setLoading(true);
    const { data } = await client({
      method: 'POST',
      url: '/uploadBanner',
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        fileName: `${agentId}.png`,
      },
    });

    const { uploadUrl, key } = data;

    await axios({
      method: 'PUT',
      url: uploadUrl,
      headers: {
        "Content-Type": "image/png",
        'Cache-Control': 'max-age=0'
      },
      data: file,
    }).catch((err) => {
      console.log(err);
    });

    await client({
      method: 'POST',
      url: '/editAgentField',
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        agentId,
        banner: key,
      },
    });

    setBanner(key);

    setLoading(false);
  };

  const handleEditClick = () => {
    openModal({
      type: 'file',
      onFileSelect: (file) => {
        (async () => {
          await upload(file);
          closeModal();
        })();
      }
    });
  };

  return (
    <div className="table-cell text-center justify-center items-center relative">
      <div>
        <button
          className={` p-1 ${bannerState ? 'bg-green-500 rounded-md w-5' : 'w-6'} relative`}
          onClick={handleEditClick}>
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
            <path className={`${bannerState ? ' stroke-white' : 'stroke-blue-500'}`} d="M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
            <path className={`${bannerState ? ' stroke-white' : 'stroke-blue-500'}`} fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M320 255.79l-64-64-64 64M256 448.21V207.79" />
          </svg>
        </button>
      </div>
    </div>
  );
};