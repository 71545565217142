import { createContext, useState } from "react";

const ModalContext = createContext();

const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);

  const openModal = (data) => setModal(data);

  const closeModal = () => setModal(null);

  return (
    <ModalContext.Provider value={{ modal, loading, openModal, closeModal, setLoading }}>
      {children}
    </ModalContext.Provider>
  );
}

export { ModalContext, ModalProvider };