import { Loading } from "../Loading";

export const Button = ({ label, onClick, disabled, hidden, selected, color, loading}) => {
  if (color === 'primary')
    return <div
      className={`flex justify-center items-center p-3 rounded-lg bg-blue-600 w-full cursor-pointer transition duration-300 ${disabled || loading ? 'opacity-50 cursor-default' : 'hover:ring-4 ring-blue-200'} ${hidden ? 'invisible' : ''} drop-shadow-lg`}
      onClick={disabled || loading ? () => { } : onClick}
    >
      <div className='text-md text-white font-bold select-none'>
        {loading ? <Loading /> : label}
      </div>
    </div>;

  return <div
    className={
      `flex justify-center items-center p-3 rounded-full ${!selected ? 'bg-neutral-200' : 'bg-blue-200 border border-2 border-blue-500'} w-full cursor-pointer transition duration-300 ${disabled || loading ? 'opacity-50 cursor-default' : 'hover:ring-4 ring-blue-200'} ${hidden ? 'invisible' : ''}`
    }
    onClick={disabled || loading ?  () => { } : onClick}
  >
    <div className='text-md text-black font-bold select-none'>
      {loading ? <Loading /> : label}
    </div>
  </div>;
};