import { Screen } from './components/Screen';
import { Outlet, Route, Routes } from "react-router-dom";

import { CreateAgent } from './components/CreateAgent';
import { CreateOrder } from './components/CreateOrder';
import { OrderList } from './components/Tables/OrderList';
import { AgentList } from './components/Tables/AgentList';
import { Modal } from './components/Modal';

//Test

const EntryScreen = () => {
  return <Screen>
    <Outlet />
  </Screen>;
};

const App = () => {
  return <>
    <Routes>
      <Route path='/' element={<EntryScreen />}>
        <Route index element={<div className='flex w-full justify-center items-center'>
          <div className='flex flex-col w-[800px] space-y-7 justify-center items-center'>
            <h1 className='text-3xl font-black text-slate-gray self-start'>Smart Form Admin</h1>
            <OrderList />
            <AgentList />
            <div className='flex space-x-7 w-full'>
              <CreateAgent />
              <CreateOrder />
            </div>
          </div>
        </div>} />
      </Route>
    </Routes>
    <Modal />
  </>;
}

export default App;
