import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import { Card } from '../Card';
import { useEffect, useState } from 'react';
import { client } from '../../api';
import { Info } from '../Info';

export const CreateOrder = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);

  const form = useForm();

  const { handleSubmit, getValues, register, formState: { errors, touchedFields } } = form;

  useEffect(() => {
    const fetchAgents = async () => {
      const { data } = await client({
        method: 'GET',
        url: '/agents',
        headers: {
          "Content-Type": "application/json",
        },
      });

      setAgents(data || []);
    };

    fetchAgents();

  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    await client({
      method: 'POST',
      url: '/order',
      headers: {
        "Content-Type": "application/json",
      },
      data,
    });
    setLoading(false);
  };


  return <div className='flex flex-col space-y-5 w-full'>
    <Card
      title={'Create an Order'}
      prompt={false}
    >
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          Agent
        </span>
        <select defaultValue={getValues('agentId') || ''} {...register('agentId', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg' placeholder='State'>
          {agents.filter(({ archived }) => !archived)
            // sort by last name and then first name
            .sort((a, b) => {
              if (a.lastName < b.lastName) {
                return -1;
              }
              if (a.lastName > b.lastName) {
                return 1;
              }
              if (a.firstName < b.firstName) {
                return -1;
              }
              if (a.firstName > b.firstName) {
                return 1;
              }
              return 0;
            })
            .map(({ agentId, firstName, lastName }) => <option value={agentId}>{lastName}, {firstName}</option>)}
        </select>
      </label>
      <label className='w-full'>
        <span class="block text-xs font-medium text-slate-gray pb-1">
          Lead Amount
        </span>
        <input name='leadCountRequested' id='leadCountRequested' type="text" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.leadCountRequested ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('leadCountRequested') || ''} {...register('leadCountRequested', { required: true })} />
        {touchedFields.leadCountRequested && errors.leadCountRequested && <p className='mt-1 text-xs text-red-400'>{errors.leadCountRequested.message}</p>}
      </label>
      <div className='bg-blue-50 p-3 rounded-xl flex flex-col space-y-3'>
        <label className='w-full font-bold text-sm'>
          Lead Flow
        </label>
        <label className='w-full'>
          <div class="flex block text-xs font-medium text-slate-gray pb-1 space-x-1">
            <div>Daily Max Flow</div>
          </div>
          <select defaultValue={getValues('dailyCaptureLimit') || null} {...register('dailyCaptureLimit', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg' placeholder={null}>
            {[
              <option value={0}>No Limit</option>,
              ...Array(20).fill(null).map((_, i) => <option value={(i + 1) * 5}>{(i + 1) * 5} leads</option>)
            ]}
          </select>
        </label>
        <label className='w-full'>
          <div class="flex block text-xs font-medium text-slate-gray pb-1 space-x-1">
            <div>Days Active</div>
          </div>
          <div className='flex space-x-2'>
            <div className='flex space-x-1 text-xs'>
              <input type="checkbox" id="sun" name="sun" defaultChecked={getValues('daysActive.sun') ?? true} {...register('daysActive.sun', { required: false })} />
              <label for="sun">Sun</label>
            </div>
            <div className='flex space-x-1 text-xs'>
              <input type="checkbox" id="mon" name="mon" defaultChecked={getValues('daysActive.mon') ?? true} {...register('daysActive.mon', { required: false })} />
              <label for="mon">Mon</label>
            </div>
            <div className='flex space-x-1 text-xs'>
              <input type="checkbox" id="tue" name="tue" defaultChecked={getValues('daysActive.tue') ?? true} {...register('daysActive.tue', { required: false })} />
              <label for="tue">Tues</label>
            </div>
            <div className='flex space-x-1 text-xs'>
              <input type="checkbox" id="wed" name="wed" defaultChecked={getValues('daysActive.wed') ?? true} {...register('daysActive.wed', { required: false })} />
              <label for="wed">Wed</label>
            </div>
            <div className='flex space-x-1 text-xs'>
              <input type="checkbox" id="thu" name="thu" defaultChecked={getValues('daysActive.thu') ?? true} {...register('daysActive.thu', { required: false })} />
              <label for="thu">Thurs</label>
            </div>
            <div className='flex space-x-1 text-xs'>
              <input type="checkbox" id="fri" name="fri" defaultChecked={getValues('daysActive.fri') ?? true} {...register('daysActive.fri', { required: false })} />
              <label for="fri">Fri</label>
            </div>
            <div className='flex space-x-1 text-xs'>
              <input type="checkbox" id="sat" name="sat" defaultChecked={getValues('daysActive.sat') ?? true} {...register('daysActive.sat', { required: false })} />
              <label for="sat">Sat</label>
            </div>
          </div>
        </label>
        <label className='w-full'>
          <div class="flex block text-xs font-medium text-slate-gray pb-1 space-x-1">
            <div>Timezone</div>
          </div>
          <select defaultValue={getValues('timezone') || null} {...register('timezone', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg' placeholder={null}>
            {[
              <option value={'HAST'}>Hawaii-Aleutian Standard Time (HAST)</option>,
              <option value={'AKST'}>Alaska Standard Time (AKST)</option>,
              <option value={'PST'}>Pacific Standard Time (PST)</option>,
              <option value={'MST'}>Mountain Standard Time (MST)</option>,
              <option value={'CST'}>Central Standard Time (CST)</option>,
              <option value={'EST'}>Eastern Standard Time (EST)</option>,
            ]}
          </select>
        </label>
        <label className='w-full'>
          <div class="flex block text-xs font-medium text-slate-gray pb-1 space-x-1">
            <div>Hours Active</div>
          </div>
          <select defaultValue={getValues('hoursActive') || null} {...register('hoursActive', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg' placeholder={null}>
            {[
              <option value={'none'}>No Schedule</option>,
              <option value={'6am-9pm'}>6am - 9pm</option>,
            ]}
          </select>
        </label>
      </div>
      <label className='w-full'>
        <div class="flex block text-xs font-medium text-slate-gray pb-1 space-x-1">
          <div>States</div>
          <Info>
            Select multiple states by holding down the control key and clicking on the states you want to select.
          </Info>
        </div>
        <select multiple defaultValue={getValues('states') || ''} {...register('states', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg h-[99px]' placeholder='State'>
          <option value="AK">Alaska</option>
          <option value="AL">Alabama</option>
          <option value="AR">Arkansas</option>
          <option value="AZ">Arizona</option>
          <option value="CA">California</option>
          <option value="CO">Colorado</option>
          <option value="CT">Connecticut</option>
          <option value="DC">District of Columbia</option>
          <option value="DE">Delaware</option>
          <option value="FL">Florida</option>
          <option value="GA">Georgia</option>
          <option value="HI">Hawaii</option>
          <option value="IA">Iowa</option>
          <option value="ID">Idaho</option>
          <option value="IL">Illinois</option>
          <option value="IN">Indiana</option>
          <option value="KS">Kansas</option>
          <option value="KY">Kentucky</option>
          <option value="LA">Louisiana</option>
          <option value="MA">Massachusetts</option>
          <option value="MD">Maryland</option>
          <option value="ME">Maine</option>
          <option value="MI">Michigan</option>
          <option value="MN">Minnesota</option>
          <option value="MO">Missouri</option>
          <option value="MS">Mississippi</option>
          <option value="MT">Montana</option>
          <option value="NC">North Carolina</option>
          <option value="ND">North Dakota</option>
          <option value="NE">Nebraska</option>
          <option value="NH">New Hampshire</option>
          <option value="NJ">New Jersey</option>
          <option value="NM">New Mexico</option>
          <option value="NV">Nevada</option>
          <option value="NY">New York</option>
          <option value="OH">Ohio</option>
          <option value="OK">Oklahoma</option>
          <option value="OR">Oregon</option>
          <option value="PA">Pennsylvania</option>
          <option value="PR">Puerto Rico</option>
          <option value="RI">Rhode Island</option>
          <option value="SC">South Carolina</option>
          <option value="SD">South Dakota</option>
          <option value="TN">Tennessee</option>
          <option value="TX">Texas</option>
          <option value="UT">Utah</option>
          <option value="VA">Virginia</option>
          <option value="VT">Vermont</option>
          <option value="WA">Washington</option>
          <option value="WI">Wisconsin</option>
          <option value="WV">West Virginia</option>
          <option value="WY">Wyoming</option>
        </select>
      </label>
      <div className='bg-blue-50 p-3 rounded-xl flex flex-col space-y-3 w-full'>
      <label className='w-full font-bold text-sm'>
          Form Options
        </label>
        <div className='flex space-x-1 text-xs'>
          <input type="checkbox" id="oneToOne" name="oneToOne" defaultChecked={getValues('oneToOne') ?? false} {...register('oneToOne', { required: false })} />
          <label for="oneToOne">1:1 Consent</label>
        </div>
        <label className='w-full'>
          <div class="flex block text-xs font-medium text-slate-gray pb-1 space-x-1">
            <div>Form Type</div>
          </div>
          <select defaultValue={getValues('type') || null} {...register('type', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold bg-neutral-200 w-full rounded-lg' placeholder={null}>
            {[
              <option value={'standard'}>Standard</option>,
              <option value={'expanded'}>Expanded</option>,
            ]}
          </select>
        </label>
      </div>
    </Card>
    <Button loading={loading} disabled={!(agents?.length)} label={'Create Order'} color={'primary'} className='mt-4' onClick={handleSubmit(onSubmit)} />
  </div >;
}